import { render, staticRenderFns } from "./estadisticasClientes.vue?vue&type=template&id=4aa9f0e0&scoped=true&"
import script from "./estadisticasClientes.vue?vue&type=script&lang=js&"
export * from "./estadisticasClientes.vue?vue&type=script&lang=js&"
import style0 from "./estadisticasClientes.vue?vue&type=style&index=0&id=4aa9f0e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa9f0e0",
  null
  
)

export default component.exports